import * as MutationTypes from '../../mutation-types';
import example from './routes/example';

const state = {
  items: [
    // example
  ]
};

const mutations = {
  [MutationTypes.MutationExpandMenu] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded;
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded;
    }
  }
};

export default {
  state,
  mutations
};

