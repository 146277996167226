<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'app',
  components: {},
  methods: {
    ...mapActions([
      'toggleDevice',
      'toggleSidebar'
    ])
  },
  mounted () {
    // let d_user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {
    const { body } = document;
    const WIDTH = 420;
    const RATIO = 3;

    const handler = () => {
      if (!document.hidden) {
        let rect = body.getBoundingClientRect();
        let isMobile = rect.width - RATIO < WIDTH;
        this.toggleDevice(isMobile ? 'mobile' : 'other');
        this.toggleSidebar(!isMobile);
        let screen_size = {
          'width': rect.width,
          'height': rect.height
        };
        this.$store.commit('MutationScreenSize', screen_size);
      }
    };

    document.addEventListener('visibilitychange', handler);
    window.addEventListener('DOMContentLoaded', handler);
    window.addEventListener('resize', handler);
    /* eslint no-extend-native: ["error", { "exceptions": ["Date"] }] */
  }
}

</script>

<style lang="scss">
/* Import Bootstrap Vue Styles */

@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';
// @import '~bootstrap-vue/dist/bootstrap-vue.css';
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
@import '~flag-icon-css/css/flag-icon.min.css';

/* Import Main styles for this application */

@import 'assets/scss/style';

</style>


