import Vue from 'vue';
import { API_WMANAGER_URL } from '@/config';
export default {
  save_this_wdmr_to_layer_by_wdmr_id_list(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/save/by_wdmr_id_list?' + query, data);
  },
  get_wdmr_list_by_wdmr_id(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/by_wdmr_id?' + query, data);
  },
  get_wdmr_list_by_algorithm(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/by_algorithm', data);
  },
  get_user_access_token(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/oauth/token', data);
  },
  get_user_mail_list(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/get_user_mail_list', data);
  },
  relation_docs_add(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/relation_docs/add', data);
  }
};

