import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  login_operation (data) {
    return Vue.http.post(API_BASE_URL + 'login_operation', data);
  },
  login () {
    return Vue.http.post(API_BASE_URL + 'v1/login');
  },
  check_url_token (data) {
    return Vue.http.post(API_BASE_URL + 'login/check_url_token', data);
  }
};

