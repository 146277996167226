export default {
  wdm16: {
    "1": "Status",
    "10733": "There is currently a problem with your network access to system servers. For this reason, you cannot use the system. Please wait, WisdomEra is trying to fix connection issues. If the connection problem continues, please contact your network (internet provider) administrator and report the situation.",
    "10898": "Negative",
    "13648": "Batch Add Options",
    "13649": "Data Entry Area",
    "13650": "Enter Separator",
    "13651": "Enter Text",
    "13652": "Prepare Options",
    "13653": "Option List to Add",
    "10899": "Positive",
    "10942": "facebook",
    "10943": "twitter",
    "10945": "instagram",
    "10947": "linkedin",
    "10983": "You can search about the artificial intelligence model you want to talk to",
    "10984": "Your Profile Page",
    "10985": "Search",
    "10986": "Speedtest",
    "10987": "wisdom data models",
    "10988": "decision making packages test screen",
    "10989": "decision making packages with sample cases",
    "10990": "system language is changing, please wait",
    "10991": "About Us",
    "10992": "Videos",
    "10993": "Corporate",
    "10994": "Frequently Asked Questions",
    "10995": "AI Robots",
    "10996": "Legal",
    "10997": "Contract & Text",
    "11013": "Data Management",
    "11014": "Internet of Things",
    "11015": "Robotic Doctor Assistant",
    "11016": "Follow Us",
    "11017": "When 100 decision support packages and 5000 algorithms are completed, the winner will be awarded.",
    "11018": "Participation criteria",
    "11019": "To participate, a decision support package with a minimum of 50 algorithms must be created.",
    "11020": "It can be joined with more than one decision support package.",
    "11021": "In algorithms, all possibilities must yield results. (* such as warning to fill the parameters expected to be filled)",
    "11022": "WAI decision support algorithms are growing rapidly",
    "11023": "The Pole Star is a module for preparing a dataset. Thanks to this module, data can be easily transferred to WisdomStats Application.",
    "11024": "Statistics can be made easily with the WisdomEra Artificial Intelligence system, which can work integrated with WisdomStats Application.",
    "11025": "Automatic articles can be produced by easily transferring the data prepared in the WisdomEra Artificial Intelligence system to the WisdomArticle Application.",
    "11026": "DrWisdom learns the artificial intelligence robot dataset. You can easily talk to Dr. Wisdom with the help of Messenger.",
    "11027": "WisdomEra Health System (WHS) Turkey and the world the existing ways of working for the medical sector is an artificial intelligence system that will change radically with the proposed IT infrastructure. WHS is able to manage automatic epicrisis and many other simplified processes with its different data architecture and integration with Hospital Information Management Systems (HIMS). Oncology Module can also serve by working integrated with this core structure. You can generate multilayered data with WisdomEra.",
    "11028": "WHS has determined the steps as 3 steps. Data management, Internet of Things and Robotic doctor assistant.",
    "11029": "Design of big data with the architecture that can be stored in an analyzable format and associating these data with evidence-based data",
    "11030": "Many devices send the measurements made by the person to WisdomEra via internet connections and provide decision support with the analyzes revealed.",
    "11031": "As a result of integrating the processes on robots and evaluating all these robots by WisdomEra, there are robots that directly assist the user.",
    "11032": "Decision Support Systems are information-based systems that help organizations choose from alternatives. WisdomEra has an infrastructure that makes decisions that are suitable for the scenarios and enables quick selection with the developed DSS.",
    "11033": "Cloud computing; It is the general name of internet-based information services that enables the use of as many resources as desired at any time for computers and other devices through data processing, storage, calculation and network infrastructure. WisdomEra is designed to be accessible from any platform, from anywhere, with its cloud infrastructure. With its scaled structure, the resources suitable for the needs are ready to be used quickly.",
    "11034": "Cloud computing",
    "11035": "Artificial intelligence is a field of study involving the development of artificial learning models by analyzing human thinking and learning methods with mathematical and statistical methods. Thanks to the most advanced artificial learning algorithms, WisdomEra is ready with its infrastructure that can process existing data.",
    "11036": "Artificial Learning",
    "11037": "Big data; It is the transformation of all the data collected from many different sources into a meaningful and processable form. When big data is interpreted with correct analysis methods, it helps institutions to make their decisions correctly. WisdomEra is designed to produce fast and accurate results with its large data processing infrastructure.",
    "11038": "Big Data",
    "11039": "Who is Dr Levent Korkmaz, CEO of WisdomEra?",
    "11040": "Dr. Korkmaz completed the Istanbul University, Cerrahpaşa Medical Faculty in 2008, and completed his Internal Diseases Specialization at Erzurum Atatürk University between 2008-2012. Having completed his Medical Oncology Specialization at Necmettin Erbakan University, Meram Medical Faculty in Konya in 2017, Dr. Korkmaz started to produce software with informatics-software studies that have been going on since his university years.",
    "11041": "Dr Korkmaz, who continues his studies on decision support systems and artificial intelligence software, founded WisdomEra A.Ş. in 2017.",
    "11042": "Research Areas",
    "11043": "Decision-support systems, Artificial intelligence, Data analysis, Statistics",
    "11044": "Areas of interest",
    "11045": "Software, Artificial Intelligence, Data Science, Statistics",
    "11046": "The rapid increase in the human population makes it difficult to reach the right scientific service. For this reason, artificial intelligence has become a need. Artificial intelligence is not a luxury. If there were no calculators today, this human population would have to spend their hours in market queues. When it comes to artificial intelligence, not only technical learning algorithms or deeply focused concepts such as deep learning should come. If the human brain is doing software and calculations that can be done, you can consider it as artificial intelligence. Because he can do it for you now. This can be with very simple algorithms or with very complex functions.",
    "11047": "With WisdomEra, modeling of detailed stratification of the data has become very simple. Scientists using the system today make the following comment about the system. 'Yes, if the system is able to tell me its experience, learn, write articles and produce hypotheses, who is the scientist?'' This question actually looks like this. The calculator can multiply two 6-digit numbers very quickly from me. Then a mathematician calculator?",
    "11048": "The answer is simple: both mathematician calculator and not. The calculator is a mathematician, but ultimately a person who produces it. When a person trained a mathematics specialist who made calculations, his name became a calculator.",
    "11049": "If we consider today that science is out of control and artificial intelligence has become a need, like calculators, then there is nothing to fear. Yes, there is no need to be afraid of artificial intelligence. A world of controlled functions is being built here. Being uncontrolled is similar to the police who could not control the gun in his waist anyway. It is essential to proceed with the approval of the relevant user.",
    "11050": "The main source of all projects should be the heart, not the brain. Because the brain can run out, but the heart never runs.",
    "11051": "WisdomEra Office",
    "11052": "WisdomEra A.Ş. is a technology company established to develop products using artificial intelligence.",
    "11053": "The company started operating in 2017 within the Konya Technology Development Zone Innopark. He then moved to his campus in Cevizlibağ, Istanbul.",
    "11054": "WisdomEra History",
    "11055": "A software called \"OLK\" was developed by Dr Levent Korkmaz, who is an Internal Diseases Specialist and a Medical Oncology Specialist, which automatically prepares the Chemotherapy schemes in 2014. This software is still used in some centers.",
    "11056": "Then, modules containing all clinical modules and data management were added to OLK software.",
    "11057": "After WisdomEra A.Ş was established in 2017, the OLK structure was transferred to multi-tiered architecture on WisdomEra.",
    "11058": "The current system is currently actively used in many hospitals with an integrated structure.",
    "11059": "Medical Artificial Intelligence, Scientific Article Projects, Decision Support Systems, Integration with HBYS, Statistics",
    "11060": "It aims to be the leading scientific data management platform in the world with its existing data management architecture.",
    "11061": "Fields of Activity",
    "11062": "Vision",
    "11063": "Mission",
    "11064": "It has determined to be at the forefront with the value it gives to people as its mission.",
    "11065": "Brand",
    "11066": "It continues its efforts to be a pioneer of quality and to demonstrate this with its brand value.",
    "11067": "Architectural",
    "11068": "It has incorporated the latest technologies with its Web Based multi-platform and cloud architecture. It has a great architecture where multiple api are talking to each other. A scalable no-sql database is used where big data can be easily operated, data management is easier.",
    "11069": "Coding",
    "11070": "HTML, CSS, JAVASCRIPT, NODE JS, VUE, PYTHON, COUCHBASE",
    "11071": "Artificial intelligence robots have become part of the messaging system. With these robots, conversations can be made on data and projects. They are trained on how to make relational analysis between which data, how to make statistical fiction. It can bring the analysis on this subject to the users. It is built on an analysis model where many algorithms such as understanding expression algorithms, natural language processing, and other prediction algorithms work together and connect.",
    "11072": "The system can be trained parametrically by installing infinite connection algorithms in the Decision Support System Rule Engine module. Thus, the system can inform the user when the related situations occur. Rules can be created easily without having detailed information. Thus, use has been simplified at an advanced level.",
    "11073": "Data models can work within themselves and interactively with other data models. Thus, even very complex algorithms can be easily created. The logical relationship between the data can be created simply.",
    "11074": "Decision Support Engine can analyze an algorithm in a very short time. Thus, packages created from tens of hundreds of rules can be analyzed in milliseconds or a few seconds.",
    "11075": "All treatments are planned automatically and agenda. The system lists the chemotherapy of the day and all treatments on one screen. The oncology module, which takes the patient into the middle circle and brings them around the staff, is used on the entire case treatments list page. Pharmaceutical stock information is presented in detail on a case-by-case or bulk drug-based basis, back and forth. Thus, planning is made more easily. Treatment planning and initiation takes as short as seconds. It provides decision support to the user during treatment approvals by analyzing laboratory data. Thus, erroneous treatments are prevented in abnormal laboratory values.",
    "11076": "The owner of the treatment can easily focus on the relevant physician with the filters. And with many more decision support system features and artificial intelligence features, the oncology module can serve.",
    "11077": "There is no need to make any calculations manually. All parameters such as glomerular filtration rate body surface area are calculated automatically by the system.",
    "11078": "Its elegant and simple design offers ease of use. The color harmony in the whole system is suitable for working without straining the eyes. All the data can be seen on the same page in the form of time series analysis from old to new or new to old. Moving to other pages for new case-related data has been greatly minimalized. When integrated with HBYS systems, texts produced from parametric data models can be automatically transferred to HBYS invoice epicrisis information. Filtering of data has been made very simple. With variables such as date, department, number of data, data type, the user can simply filter their data.",
    "11079": "Department data type relationships have been established. Department & User relations have been established. Thus, users can easily be associated with the data with the authorization method.",
    "11080": "Users or administrators can create suitable data models as they want. They are also able to save these data models on cases without the need to write any software code.",
    "11081": "The polar star module is the module that allows the data to be filtered automatically without code and that the user can work on this data. It works by talking with statistics module, automatic article module and artificial intelligence robots. Data can be filtered with infinite complexity with the help of Decision Support Engine. Thus, the desired data set can be easily created without the need for software functions. By creating a project, users can be included in projects if desired. Thus, case information can easily be placed in rows and cells. Since all the data routinely flows into the system during the follow-up of the cases, the data can be easily accessed without any additional effort.",
    "11082": "Whichever parameters are desired to be worked on, these parameters are simply created. From these parameters, the system produces columns for cases.",
    "11083": "The process of creating column parameters can also be integrated with the Decision Support Engine, making the process more complex.",
    "11084": "Filtering can be done. Many activated statistical functions can be implemented simply. These outputs can be seen in the WisdomStats Analysis section and can be recorded in relation to the respective dataset. With the anonymization function, the desired column can be anonymized. WisdomStats Module is actively used with many statistical functions and other features that are still being added. Analyzes made on the analysis screen are presented graphically. Thus, there is no need for additional media to see with graphics.",
    "11085": "Relations between columns can be easily established with the WisdomStats module.",
    "11086": "Data set can be transferred to Excel if analysis results are desired. After the filter is made, the related filtered data set can also be transferred to the analyzes.",
    "11087": "General Information, System Architecture",
    "11088": "How should I understand the relationship of this software with HIMS?",
    "11089": "WisdomEra is positioned between the user and HIMS software, which manages billing controls and thus insurance processes. Its role is to provide clinical decision support and management. In addition, HIMS produces and sends automatic epicrises to invoice modules.",
    "11090": "Can we use it without being integrated with HIMS systems?",
    "11091": "Epicrisis invoicing can be used without integration if data is not wanted to be sent to structures such as SSI, or if general clinical follow-up is planned, such as the management of chemotherapy treatments. With integration, results such as laboratory results begin to come to the system automatically.",
    "11092": "Can we learn this software easily?",
    "11093": "The software is designed as easy-to-understand screens. The system is very easy to understand, since many operations can be performed on one page.",
    "11094": "Yes, data models can be created easily in the software. These data models become easy to save on the case. The authority of this data model is also in the person who prepared it. It can assign data models to other people they want. Thus, if projects with different data structures are to be started, it can be started with a few clicks.",
    "11095": "The system can be made ready for use within the same day after the servers are specified and given to us. If integration is desired, this period may vary depending on the HIMS system administrators to be integrated. Integration services are available by WisdomEra.",
    "11096": "Around 600 templates related to chemotherapy have been prepared to be automatically selected in the system. This includes premedications. The user can customize it.",
    "11097": "All users can manage their treatments from a single screen. Thus, most of the time they do all their operations without going from one page to another.",
    "11098": "Each step is tied to detailed powers. Processes such as a nurse without a doctor procedure and a pharmacist without a nurse procedure are connected to each other with a security circle.",
    "11099": "The system calculates all these values instantly and warns the user if there are changes in the follow-up related drug dose.",
    "11100": "Yes, the system performs SUT analysis while the user records the treatment in the system. Thus, in which case, the treatment will be started, it can be easily continued by selecting this. Thus, SUT evaluation is made by the system.",
    "11101": "No. Here, you can prepare your dataset by simply filtering age> 35, diagnosis = breast malignant neoplasm. This can be done from simple algorithms to complex algorithms.",
    "11102": "Yes. You can establish a detailed history relationship, such as whether the pathology is after the surgery or between the dates.",
    "11103": "It works with unlimited connection algorithm. It can analyze independently from the other rule.",
    "11104": "Yes. You can create appropriate decision support packages for you and design your active work in the desired department or users. You can also open these packages to the global in the wisdom rule market, which will be activated later.",
    "11105": "The system writes the purpose part of what parametric data (input, output) is being worked on in the article. In the material method, he writes which patient group is working. He translates all the statistics to the article and transfers it to the conclusion of the article. After that, it leaves the regulations in other sections to the user.",
    "11106": "Yes, for this, after preparing the data set without having any software knowledge, the system does the modeling itself. You can start talking immediately with this model.",
    "11107": "1 hour login period is actively defined in the system. If there is no operation for 1 hour, the system automatically exits. However, this period can be changed with the hospital management.",
    "11108": "Security algorithms to protect itself have been defined in the system. In case of an attack recognition, the user is prevented from continuing his operations. Having a corporate VPN line is of course the most recommended security mechanism.",
    "11109": "All transactions made in the system are logged in detail. And with instant monitoring screens, it is possible to monitor who performed all transactions.",
    "11110": "No. In the structure designed with asynchronous network architecture, multiple users can perform their operations on the system quickly.",
    "11111": "Yes. With our Network Holter module, you can easily see which user is slow at which time of day, in milliseconds and graphs.",
    "11112": "Can I create new data models for my own cases in the software?",
    "11113": "How long can you do the setup and activation of WisdomEra?",
    "11114": "Are chemotherapy treatments available on the Oncology Module, do we need to save our templates?",
    "11115": "The cases are nurse, pharmacy etc. how do we manage their processes?",
    "11116": "How is the treatment approvals authorization.",
    "11117": "Does the system calculate values such as body surface area, glomerular filtration rate?",
    "11118": "Treatments Compliance with health practice notification is very important in oncology, is there any convenience about this?",
    "11119": "Do I need a software developer while preparing my data in the Pole Star Module?",
    "11120": "Can I establish a date relation when preparing the data?",
    "11121": "Does the rule engine run on infinite algorithms or is it limited to the tree algorithm.",
    "11122": "Can I create my own rule packs (Decision Support Packs)?",
    "11123": "What areas does the system complete in the article? What is left in addition to me?",
    "11124": "Can any user create a model to talk to Artificial Intelligence robots?",
    "11125": "How long is my active login in the system?",
    "11126": "Do you have an architecture suitable for attacks on your system?",
    "11127": "Do you keep the log of the transactions made in the system?",
    "11128": "Are the users active in the system waiting for the other user during the process?",
    "11129": "Can we see the speed problems of our active users on the network?",
    "11130": "Oncology Module",
    "11131": "Pole Star Module",
    "11132": "Decision Support System Rule Engine",
    "11133": "Article Module",
    "11134": "Security",
    "11135": "Network",
    "11136": "no decision support package selected",
    "11137": "Decision Support Packages",
    "11138": "decision support package selection",
    "11139": "add new decision support package",
    "11140": "edit selected decision support package",
    "11141": "delete selected decision support package",
    "11142": "add algorithm rule to selected decision support package",
    "11143": "Publish decision support package (WAI)",
    "11144": "decision support package algorithm drawing",
    "11145": "analyze",
    "11146": "Click on the relevant line to select the rule. You can click on the '...' field on the far right to show details of the rule data. Here you can edit rule algorithms.",
    "11147": "Algorithm",
    "11148": "List",
    "11149": "Copy Algorithm",
    "1116": "Diagnosis",
    "1118": "General",
    "11188": "Edit Algorithm",
    "11189": "Algorithm drawing",
    "11190": "Quick Test",
    "11191": "Test with Case",
    "11192": "Test Case",
    "11193": "Decision Support System Analysis Results",
    "11194": "Rule Algorithm",
    "11195": "Rotate counterclockwise",
    "11196": "Rotate clockwise",
    "11197": "Show & hide texts",
    "11198": "Change color",
    "11199": "Color change animation",
    "11200": "Increase color change speed",
    "11201": "Decrease color change speed",
    "11202": "Clockwise rotation animation",
    "11203": "Increase rotation speed",
    "11204": "Decrease rotation speed",
    "11205": "Increase algorithm depth",
    "11206": "Decrease algorithm depth",
    "11207": "Algorithm depth animation",
    "11208": "Increase depth speed",
    "11209": "Decrease depth speed",
    "11210": "Pause & Continue",
    "11211": "Minimize",
    "11212": "Okay",
    "11213": "Preview",
    "11214": "Document List",
    "11215": "Show Figure",
    "11216": "Related record",
    "11217": "Algorithm Design",
    "11218": "Tables",
    "11219": "Figures",
    "11220": "Decision Support Package Algorithm",
    "11221": "About Decision Support Package",
    "11222": "Open Close Top Area",
    "11223": "It allows you to write fast rules. You can do this by putting andes.",
    "11224": "You can use this mode if you want to edit your queries in detail. (and, or and other parentheses algorithms)",
    "11225": "You can use this mode if you want to write detailed rules and see all parameters together.",
    "11226": "PARAM",
    "11227": "PARAM & QUERY",
    "11228": "WDM & QUERY",
    "11229": "Parameter",
    "11230": "add new column query",
    "11231": "add field to query",
    "11232": "new query",
    "11233": "add to your query",
    "11234": "add to query (If this parameter is not full, - missing-)",
    "11235": "Data Parameters",
    "11236": "Please close all open parentheses",
    "11237": "Rule Details",
    "11238": "Algorithm Details",
    "11239": "Only the column is produced from the record of the selected parameter. Along with other parameters, the column parameter is checked to see if it meets the relevant status.",
    "11240": "Choosing an Option",
    "11241": "plain text",
    "11242": "historical data",
    "11243": "digital data",
    "11244": "equality",
    "11245": "Any",
    "11246": "Anatomy Family",
    "11247": "select anatomy region",
    "11248": "select anatomy family",
    "11264": "Save Data to Patient",
    "11265": "Setting Buttons View",
    "11266": "Activate the Appearance of Buttons",
    "11267": "Open Settings Window",
    "11268": "Adds a data model to the form",
    "11269": "Edit recently saved data for this form",
    "11270": "Clear form I will add new data",
    "11271": "Save these form settings to me",
    "11272": "Simple View Mode",
    "11273": "Left-to-Right Navigation View Mode",
    "11274": "From up to down",
    "11275": "Page formatting mode",
    "11276": "Form headers mode",
    "11277": "Favorite parameter view mode",
    "11278": "Anatomy Component",
    "11279": "Anatomy Details Column Partitioning",
    "11280": "Anatomy template component",
    "11281": "Open-Close All",
    "11282": "Choose From Template",
    "11283": "Group Titles",
    "11284": "Column partitioning",
    "11285": "WDM Parameter Settings",
    "11286": "Parameter Input Form",
    "11287": "Parameter & Value Settings",
    "11289": "No data model attached yet",
    "11290": "Settings Management Panel",
    "11291": "Sub parameter",
    "11292": "Title",
    "11293": "Add new data model",
    "11294": "Cannot be undone after saving!",
    "11295": "Update language conversion fields in all wdms",
    "11296": "Update these wdmin language conversion fields",
    "11297": "wisdom operation",
    "11298": "Data Structure",
    "11299": "Model List",
    "11300": "Group & Param",
    "11301": "Modules",
    "11303": "Test",
    "11304": "Authorize other users",
    "11305": "Select-Remove All Users",
    "11306": "Grant selected permissions to selected users",
    "11307": "User Filtering Area",
    "11308": "Module Selection",
    "11309": "No Module Selection Has Been Made Yet. If you do not select a module, your data structure will not be activated.",
    "11310": "Wisdom Data Models List",
    "11311": "WisdomEra Models",
    "11312": "Create Draft",
    "11313": "Delete Data Model",
    "11314": "Publish Data Model as New Version",
    "11315": "Hospital Models",
    "11316": "User Models",
    "11317": "Model Operations",
    "11318": "Data General Information",
    "11319": "Wdm Design",
    "11320": "Edit Wdm Design",
    "11321": "Data Code",
    "11322": "Data Deletion",
    "11323": "This model cannot be deleted as it is updated with the version.",
    "11324": "Data Model Title Language No",
    "11325": "Will the rule be used as a parameter group?",
    "11326": "Sending to the patient HIMS application protocol",
    "11327": "Version",
    "11328": "Department Filtering Relationship",
    "11329": "Cannot be associated with the department",
    "11330": "Department dependent filter",
    "11331": "Show all department data",
    "11332": "Data Model Color",
    "11333": "Model Owner",
    "11334": "Model Language",
    "11335": "Data model that cannot be saved",
    "11336": "Only once",
    "11337": "Multiple recordable",
    "11338": "Editing in Hospital Settings",
    "11339": "Ability to be Authorized by the Hospital Authority",
    "11340": "Which Authorities the Hospital Official Can Give",
    "11341": "Creating a template",
    "11342": "Ability to use old data",
    "11343": "Data Model Owner Type",
    "11344": "Display of Favorite Parameters",
    "11345": "Weight & Height & GFR",
    "11346": "Calculation with Parameter Function",
    "11347": "Listing on opening",
    "11348": "Display of the Last Saved Value",
    "11349": "Data Model",
    "11351": "Rule ID",
    "11352": "Rule Name",
    "11353": "Rule type",
    "11354": "Response type",
    "11355": "Recommended actions",
    "11356": "Reference Details",
    "11357": "Reference name",
    "11358": "Reference type",
    "11359": "Reference source",
    "1136": "Frequency",
    "11360": "Link",
    "11361": "Created By",
    "11362": "Query Analysis Details",
    "11363": "Wisdom Data",
    "11428": "departments",
    "11442": "Remove Table",
    "11443": "Submit Table to Article",
    "11444": "Download as Excel",
    "11445": "Line Filtering Area",
    "11446": "Column Filtering Area",
    "11447": "Cell Full View",
    "11448": "Favorite Status",
    "11449": "When it is green, it means it is present in your favorite list.",
    "11450": "list details",
    "11451": "choose new group",
    "11452": "auto calculate",
    "11453": "Please wait",
    "11454": "T, N, M ... groups are listed.",
    "11455": "After searching, select from the list and click the add button.",
    "11456": "add new",
    "11457": "You can search by disease name or ICD code",
    "11458": "disease is listed.",
    "11459": "You can search by product name, content.",
    "11460": "Choose the drug from the list",
    "11461": "Choose product from the list",
    "11462": "Image selection area",
    "11463": "Upload the picture",
    "11464": "please make all choices",
    "11465": "you can search",
    "11466": "Add medicines",
    "11467": "delete picture",
    "11468": "Rule",
    "11469": "preview selected image",
    "11471": "add column field to query",
    "11472": "Template Group",
    "11473": "Save Template as Updated",
    "11474": "Table View",
    "11475": "Sentence View",
    "11476": "document & prepared data",
    "11477": "Project statuses",
    "11605": "delete model",
    "1178": "Days",
    "1179": "Period",
    "1246": "Day",
    "12629": "( version:",
    "12630": "list tests in detail",
    "12631": "list test ID as one",
    "12632": "HBYS tests are listed.",
    "12633": "no language matching yet",
    "12634": "inappropriate file type",
    "12635": "File size cannot be more than 2 MB",
    "12638": "New words & phrases in the data model are analyzed.",
    "12639": "You will be informed when the language control process is completed for all WDMs. Information is also provided as each wdm is updated.",
    "12640": "About Data Model Language Operations",
    "12641": "Are You Sure You Want to Publish the Data Model?",
    "12643": "Publishing is complete.",
    "12644": "About the Data Model Publishing Process",
    "12646": "Are You Sure You Want To Delete The Data Model?",
    "12647": "Your data model deletion is complete.",
    "12648": "User permissions are edited",
    "12649": "This is the grouping title. Please choose the appropriate module.",
    "12652": "The changes have been reverted.",
    "12653": "Data Reset Process",
    "12654": "Your data model is being saved. Please wait.",
    "12656": "Data Logging Process",
    "12658": "Please fill in the model title",
    "12659": "Please fill in the model language",
    "12660": "About Data Model Registration",
    "12661": "Registration is complete.",
    "12663": "loading data model",
    "12664": "Loading data model list",
    "12665": "There is an option with this name",
    "12666": "If you perform this process, the previous parameter information structure will be converted to the one on the copied side.",
    "12667": "We cannot perform the transaction without deleting the registered parameters in the group.",
    "12668": "About Group Deletion",
    "12670": "Information about the model has been added.",
    "12671": "please 0 -",
    "12672": "enter a value in the range",
    "12673": "About the Model",
    "12674": "this option has been added before.",
    "12675": "About Option",
    "12676": "Please fix any errors in your data structure. [{\"label\": \"\", \"value\": \"\", translation: {\"tr\": \"\", \"en\": \"\"}}]",
    "12677": "Check the json structure.",
    "12678": "This parameter group is attached. Please edit the group name",
    "12679": "Parameter Group Information",
    "12680": "The existence of the parameter depends on the parameter you are currently deleting. If you delete this parameter, the connected parameter will also be deleted.",
    "12681": "This parameter exists in the area you want to move.",
    "12683": "Are you sure you want to delete?",
    "12684": "It is used as a status mate in parameter.",
    "12686": "If you do not complete the registration process, the new parameter you added will not be saved.",
    "12687": "parameter label value is available. Please change",
    "12691": "Please check the operators in your rule",
    "12692": "Please check your transactions.",
    "12693": "The numbers of opening and closing brackets are complete. It is not appropriate to add a new closing bracket.",
    "12694": "Post operation is not suitable for adding closing parentheses.",
    "12695": "The structure is not suitable for adding \"and\" & \"or\".",
    "12696": "Please select a wdm data model first to be able to add and subtract from the date.",
    "12698": "At the end, the wdm data model appears to be attached. It is not appropriate to add it again.",
    "12699": "At the end, a wdm manual date appears to be attached. It is not appropriate to add it again.",
    "12700": "At the end, there is a wdm manual day difference. It is not appropriate to add it again.",
    "12701": "Please do the opening parenthesis first.",
    "12703": "Please write something first.",
    "12704": "You can choose another parameter to change the column.",
    "12705": "The model is being fetched. Please wait.",
    "12708": "Wdm language is being updated. Please wait.",
    "12709": "Your wdm settings are being updated. Please wait.",
    "12712": "Updating is done in your favorite parameters. Please wait.",
    "12713": "Your registration is complete.",
    "12714": "Registration error",
    "12715": "Updating your title settings. Please wait.",
    "12718": "WDM Build Time",
    "12719": "WDMi Creator",
    "12720": "WDMi Creator (real)",
    "12721": "Wdm Settings",
    "12722": "User list fetching",
    "12724": "WDM Template registration process started",
    "12726": "WDM and Anatomy templates are being brought in",
    "12727": "WDM Anatomy Template registration process started",
    "12728": "WDM Group registration started",
    "12729": "WDM Anatomy templates fetching",
    "12730": "File size cannot be more than 10 mb.",
    "12731": "Please",
    "12732": "use one of the formats",
    "12733": "File size cannot be more than 10mb",
    "12734": "Old tests belonging to the patient are loaded ...",
    "12735": "Loading your template list ...",
    "12738": "Error information",
    "12739": "fetching template ...",
    "12740": "Your template registration is being processed",
    "12742": "Error code:",
    "12743": "template registration is complete.",
    "12745": "Anatomy site not selected.",
    "12746": "Staging Parameters Preparing ...",
    "12747": "Not Found",
    "12748": "Date Type",
    "12749": "Year Only",
    "12750": "Year month",
    "12751": "Year-Month-Day",
    "12752": "Date and time",
    "12753": "Permitted Date Range",
    "12754": "No limit",
    "12755": "Just Today and Before",
    "12756": "Only Today and After",
    "12757": "Entering Due Date",
    "12758": "End Date User Initiative",
    "12768": "Document Types",
    "12769": "Patient Tracking",
    "12770": "About",
    "12771": "Main parameter selection",
    "12772": "All",
    "12773": "Use in naming (N)",
    "12774": "Show when adding data (ASK)",
    "12775": "Show after recording (SAR)",
    "12776": "Required (RE)",
    "12777": "Kural (RU)",
    "12778": "Laboratory unit (LU)",
    "12779": "Length units (SU)",
    "12780": "Weight units (WU)",
    "12781": "Dose units (DU)",
    "12782": "Anonymous date (A)",
    "12783": "Cannot be a template (Ş)",
    "12784": "HBYS Matching Operations (HMO)",
    "12785": "Pairing",
    "12786": "parameters",
    "12787": "You can filter the parameters.",
    "12788": "Money & Arc",
    "12789": "Param & Ark & Fonk",
    "12790": "Money & Main",
    "12791": "Param & Hasta",
    "12792": "Add new parameter",
    "12793": "Select & Arc",
    "12794": "Select & Home",
    "12795": "Select & Patients",
    "12796": "Add Group",
    "12797": "Group Detail",
    "12798": "Param & Test co.",
    "12799": "pairing",
    "12800": "Please select the tests for the matching process.",
    "12801": "HBYS",
    "12802": "Type something to search.",
    "12803": "all of",
    "12804": "rule options",
    "12805": "bucket",
    "12806": "bucket type value",
    "12807": "record type",
    "12808": "multiple",
    "12809": "only one",
    "12810": "operator (default)",
    "12811": "add appropriate data type",
    "12812": "request data",
    "12813": "recorded data",
    "12814": "suitable data types",
    "12815": "of tipi (default)",
    "12818": "time of parameter value (default)",
    "12819": "last registered value",
    "12820": "in the last XXX days",
    "12821": "any time",
    "12822": "Does the data contain \"date\" variable?",
    "12823": "includes",
    "12824": "does not contain",
    "12825": "request function",
    "12826": "wisdom data record",
    "12827": "What is the query meaningful? (Default)",
    "12828": "if the result is true (true)",
    "12829": "if the result is false (false)",
    "12830": "Transactions",
    "12831": "Suitable for use in function",
    "12832": "Ready Parameters",
    "12833": "Manuel Parameters",
    "12834": "No Parameter Function Definition",
    "12835": "options",
    "12836": "Please add an option to the parameter first in order to associate the options.",
    "12837": "Friend relations with other parameters of the option",
    "12838": "get all",
    "12839": "if anyone is enough",
    "12840": "delete friend info",
    "12841": "copy friend info",
    "12842": "family",
    "12843": "parameter not in family list",
    "12845": "copy friend info",
    "12846": "out of family",
    "12847": "delete mother object parameter",
    "12848": "mother object parameter",
    "12849": "No parameter friend relationship",
    "12851": "Eligibility for friends",
    "12852": "parameter must be filled",
    "12853": "independent of options",
    "12854": "Mom parameter options",
    "12866": "anatomy relations",
    "12867": "Anatomy relations of option",
    "12868": "Anatomy Zone",
    "12869": "Mother anatomy",
    "12870": "delete anatomy",
    "12871": "copy anatomy",
    "12872": "Anatomy family group",
    "12875": "No parameter anatomy relationship",
    "12880": "No option anatomy relationship",
    "12883": "patient characteristics relationships of the option",
    "12884": "Patient Feature",
    "12885": "Patient characteristics",
    "12886": "copy relationship",
    "12887": "No relation between parameters and patient characteristics",
    "12888": "Patient characteristics relationship",
    "12889": "No option patient characteristics relationship",
    "1289": "History",
    "12890": "Parameter type",
    "12891": "Parameter title",
    "12892": "Defining the parameter code",
    "12893": "Parameter code",
    "12894": "THE SAME VALUE AVAILABLE PLEASE CORRECT YOUR VALUE",
    "12895": "Please login",
    "12896": "Allowed characters: less 0-9 _ -",
    "12897": "Cannot end with underscores (_) and hyphens (-)",
    "12898": "object list options mother parameter",
    "12899": "add parameter",
    "12900": "Group Auto Add",
    "12901": "Group Title",
    "12902": "I Will Define the Group Code Myself",
    "12903": "add group",
    "12905": "the value is protected, please enter another value",
    "12906": "Group Deletion",
    "12907": "delete group",
    "12909": "Group code",
    "12910": "Group title language number",
    "12911": "delete parameter",
    "12912": "Parameter name",
    "12914": "Parameter Name Language No",
    "12915": "Number of lines",
    "12916": "select",
    "12917": "radiogroup",
    "12918": "Create a rule",
    "12921": "Show While Recording (ASK)",
    "12922": "Active while recording",
    "12923": "Laboratory Unit Use",
    "12924": "Use Laboratory Unit",
    "12925": "Using the Unit of Length",
    "12926": "Use Unit of Length",
    "12927": "Weight Unit Usage",
    "12928": "Use Unit of Weight",
    "12929": "Dose Unit Usage",
    "12930": "Use Dose Unit",
    "12931": "Can&#39;t be a template",
    "12933": "Anonize Data",
    "12935": "Use in Automatic Naming",
    "12938": "Using the title in naming",
    "12939": "Show After Registration",
    "12941": "HBYS Matching Operations",
    "12943": "Minimum limit",
    "12945": "Maximum limit",
    "12947": "vSelect",
    "12948": "Listbox",
    "12949": "Chart",
    "12950": "Sentence",
    "12951": "Polestar",
    "12952": "DISPLAY",
    "12953": "WDM Specific View Mode",
    "12955": "Compulsory",
    "12956": "Registration obligation",
    "12957": "Visibility while recording",
    "12958": "Show While Recording",
    "12974": "Last Number of Saved Values Will Be Displayed",
    "12975": "Option Creation Method",
    "12976": "Manual creation",
    "12977": "Creating with a function",
    "12978": "Another function is filling",
    "1298": "Start",
    "12980": "Select WTL Type",
    "12981": "Hospital Location List",
    "12982": "chemotherapy",
    "12983": "Object list form",
    "12984": "Object registration type",
    "12985": "Default Option",
    "12986": "Parameter picture",
    "12987": "Delete Picture",
    "12988": "Choose picture",
    "12989": "Upload the picture ...",
    "12990": "icon",
    "12991": "under",
    "12992": "New option",
    "12993": "Option Value",
    "12994": "Define option code",
    "12995": "Option Transactions",
    "12997": "Adding options via json text",
    "12998": "Sort (AZ)",
    "12999": "Option Title",
    "13000": "codes",
    "13001": "Edit Option",
    "13002": "Delete Option",
    "13004": "up",
    "13005": "to the top",
    "13006": "the lowest",
    "13009": "Copy parameter",
    "13010": "Get parameter json structure",
    "13012": "Add the json structure of the parameter you want to add:",
    "13013": "Add the json structure of the options:",
    "13014": "Clear Current Options?",
    "13015": "Auto-generate option codes (by number)",
    "13016": "Option text",
    "13017": "Move parameters",
    "13018": "enter the queue to move",
    "13019": "Move",
    "13020": "New data model",
    "13026": "Document Dating",
    "13027": "Document Dating User Initiative",
    "13033": "Adding Due Date",
    "13038": "Data Model Region",
    "13039": "user",
    "1304": "anatomy",
    "13040": "Page to use",
    "13042": "About the Data Model",
    "13043": "Design Screen",
    "13044": "General Algorithm",
    "13045": "add all by putting \"and\" connectors",
    "13046": "add all by putting the \"or\" connector",
    "13047": "detailed edit screen",
    "13048": "delete queries (clears parameters you added along with the algorithm)",
    "13049": "Attached Number of Queries",
    "13050": "No Queries Attached",
    "13051": "Historical Algorithm",
    "13052": "detailed editing mode",
    "13055": "Output Lettering Algorithm",
    "13061": "Delete the algorithm. It does not delete added queries. It just allows you to recreate the algorithm.",
    "13062": "Add to algorithm",
    "13063": "Add to model",
    "13065": "Your algorithm is empty please add",
    "13066": "Query List",
    "13068": "Add to date algorithm",
    "13071": "It allows adding expressions to the algorithm.",
    "13072": "back",
    "13074": "any",
    "13075": "any anatomy",
    "13080": "Add to date relationship list",
    "13081": "Add all of them to the model, putting \"and\" connectors between query parameters",
    "13087": "if the result is positive",
    "13088": "if the result is negative",
    "13089": "in the last XXX days",
    "13090": "query",
    "13095": "during the day",
    "13096": "if the result is positive",
    "13097": "if the result is negative",
    "13098": "Areas",
    "13101": "this group",
    "13103": "This area is defined as a column.",
    "13104": "gather",
    "13105": "how many",
    "13106": "Yes No",
    "13108": "is negative (there is no parameter or if it is not the following options)",
    "13109": "if negative (parameter must exist and full, other options)",
    "13111": "this anatomy",
    "13112": "anatomy family",
    "13113": "Anatomy Selection Screen",
    "13114": "anatomy selected",
    "13125": "stop",
    "13134": "add date",
    "13135": "add day difference",
    "13136": "Enter the day difference",
    "13137": "Add expression field",
    "13138": "Enter your statement",
    "13139": "add phrase",
    "13140": "Artificial Intelligence Robot",
    "13141": "Voice analysis started.",
    "13142": "min:",
    "13143": "max:",
    "13144": "start:",
    "13145": "finish:",
    "13146": "talk time (ms):",
    "13147": "waiting time (ms):",
    "13148": "total:",
    "13149": "Stop",
    "13150": "stop",
    "13151": "Parameters in Order",
    "13152": "Natural Language Processing",
    "13153": "Please Repeat",
    "13154": "Parameter header settings",
    "13155": "border line type",
    "13156": "dotted",
    "13157": "border line thickness",
    "13158": "border line color",
    "13159": "back color",
    "13160": "boundary corner curvature",
    "13161": "font",
    "13162": "text color",
    "13163": "after parameter",
    "13164": "Parameter value settings",
    "13167": "flat",
    "13173": "Text color",
    "13175": "HBYS Data Matching Definitions",
    "13176": "parameters...",
    "13177": "summary",
    "13178": "copy information in",
    "13179": "Clear Anatomy Data",
    "13180": "Wisdom Parameters Modal",
    "13182": "to the top",
    "13184": "Uploaded",
    "13185": "Was recorded",
    "13186": "Checked",
    "13187": "Edit Wdm Details",
    "13188": "Detailed Mode",
    "13189": "Update Wdm Settings",
    "13190": "Change language",
    "13192": "Quick Mode",
    "13193": "Update Wdm Settings",
    "13196": "Filter by related parameters",
    "13197": "Choose from Patient Data",
    "13198": "Fill in data by talking to artificial intelligence",
    "13199": "Instant naming mode",
    "13200": "Change Data Owner",
    "13201": "Wdm Designer",
    "13202": "Wdm Designer Settings",
    "13203": "WDMR Data Owner Selection Screen",
    "13204": "History (*)",
    "13209": "Expiry Date (*)",
    "13214": "settings are used. Click to change",
    "13215": "Choose your favorite parameters",
    "13217": "Saves Your Favorite Parameters",
    "13218": "Select Your Parameters whose Title Will Not Be Visible When Filled",
    "13219": "Select Parameters whose title is not visible when filled",
    "13220": "Saves Your Parameters whose Title Will Not Be Visible When Filled",
    "13221": "loading model ...",
    "13222": "Choose a file ...",
    "13223": "Drop the file ...",
    "13224": "Download Document",
    "13228": "Prepared data",
    "13229": "Template group adding screen",
    "13230": "How to Register?",
    "13231": "As My Template",
    "13232": "As a Hospital Template",
    "13233": "As Wisdom Template (wisdom and administrator privileges)",
    "13239": "Date edit screen",
    "13244": "Patient Search",
    "13245": "Get Changes",
    "13246": "Active Language",
    "13247": "Language selection",
    "13271": "Group Title Code",
    "13272": "Don't Use the Parameter Title in Naming",
    "13273": "Loading forms. Please wait.",
    "13274": "About the save process",
    "13275": "Save process is completed",
    "13276": "Don't look for value",
    "13277": "When it is determined as looking at the value, the asset is checked up to the relevant selected location in the rule engine. The value in return is not considered. If the location is determined, it is accepted as correct.",
    "13278": "Equals",
    "13279": "Checks if the value of the saved parameter is equal to the value specified here or one of the values. If it is equal, it is considered correct.",
    "13280": "Does Not Equal",
    "13281": "Checks if the value of the saved parameter is equal to the value specified here or one of the values. If it is not equal, it is considered correct.",
    "13282": "Bigger than",
    "13283": "It checks whether the value of the saved parameter is greater than the value specified here. If it is large, it is considered correct.",
    "13284": "Bigger than and equal to",
    "13285": "Checks if the value of the saved parameter is greater than or equal to the value specified here. If it is greater or equal, it is considered correct.",
    "13286": "Smaller than",
    "13287": "It checks whether the value of the saved parameter is less than the value specified here. If it is small, it is considered correct.",
    "13288": "Smaller than and equal to",
    "13289": "Checks whether the value of the saved parameter is less than or equal to the value specified here. If it is less than or equal, it is considered correct.",
    "13290": "Includes",
    "13291": "Checks if the value of the saved parameter contains the value specified here. If it does, it is considered correct. Makes the control in writing. If that text is in it, it means",
    "13292": "Not includes",
    "13293": "Checks if the value of the saved parameter contains the value specified here. If not, it is considered correct. Makes the control in writing. If that text is in it, it means",
    "1675": "Information",
    "1677": "Organization",
    "1701": "solid",
    "1741": "Primary or metastatic focus",
    "1762": "The amount of necrosis",
    "20": "Adress",
    "2391": "Gist risk assessment",
    "2444": "Response to neoadjuvant therapy",
    "2451": "Intratumoral lymphocytic response",
    "2454": "Peritumoral lymphocytic response",
    "2480": "Histologic grade",
    "2500": "Perineural invasion",
    "2756": "Sarcomatoid, rhabdoid feature",
    "2760": "Nuclear Pleomorphism",
    "2762": "Skin Findings",
    "2769": "Mucinous tumor component ratio (%)",
    "2771": "Medullary tumor component ratio (%)",
    "2781": "clear",
    "2993": "Ovarian integrity",
    "3280": "Template Name",
    "3317": "General information",
    "3426": "Statistics",
    "35": "Telephone ",
    "36": "Mail Adress",
    "3667": "Detail",
    "3702": "Uploading Documents",
    "3732": "All",
    "3733": "Planned dosage",
    "3734": "The last status",
    "3735": "cc/hour",
    "3736": "Venous beading",
    "3737": "Close schema",
    "3738": "Treatment stopped because of toxicity",
    "3739": "Toric IOL",
    "3740": "Adjusted weight",
    "3741": "Intolerability",
    "3742": "Why could not lymph vascular invasion be assessed",
    "3743": "Fibrovascular changes",
    "3744": "GFR",
    "3745": "Is there myometrium invasion",
    "3746": "Anadolu Medical Center",
    "3747": "Could not be applied (no vascular tract)",
    "3748": "Close",
    "3749": "Add Toxicity",
    "3750": "Tumor features",
    "3751": "ECG details",
    "3752": "Order",
    "3753": "Patient preference",
    "3754": "Dosage change",
    "3755": "Distal surgery margin",
    "3756": "Private",
    "3757": "Gender",
    "3758": "Notes",
    "3759": "Macular edema with leakage",
    "3760": "Histologic Grade (low or high)",
    "3761": "Soft exudate",
    "3762": "This",
    "3763": "Ki-67 labeling index (%)",
    "3764": "Note",
    "3765": "Note",
    "3766": "Creatinine date",
    "3767": "Arterial filling defects (central retinal artery occlusion)",
    "3768": "Protocol",
    "3769": "Zone - 2",
    "3770": "Specify location as oclock",
    "3771": "Diabetic papillopathy with leakage",
    "3772": "Select a tumor extension type",
    "3773": "General information",
    "3774": "The last BSA calculation",
    "3775": "Sum",
    "3776": "Why cannot tumor diameter be asssessed",
    "3777": "Please select the premedication",
    "3778": "Favorite",
    "3779": "Arterial ischemic optic neuropathy",
    "3780": "Fluid",
    "3781": "Protocol details",
    "3782": "Is there mucinous tumor component",
    "3783": "Protocol groups list to save on",
    "3784": "Squamous differentiation percentage (%)",
    "3785": "Distance between proximal surgery margin and the tumor (mm)",
    "3786": "hours",
    "3787": "Log out",
    "3788": "Distance between circumferential margin and tumor (mm)",
    "3789": "Drug & Cycle view",
    "3790": "MFIOL",
    "3791": "Wait",
    "3792": "Add mixing drug",
    "3793": "Right eye temporal",
    "3794": "Why could not perineural invasion be assessed",
    "3795": "Toxicity",
    "3796": "Options",
    "3797": "ACIOL",
    "3798": "Patient & Drug matching",
    "3799": "Auto created title",
    "3800": "Neovascularization (other)",
    "3801": "Calculated dosage",
    "3802": "Show last operations",
    "3803": "Date",
    "3804": "Ready-to-use",
    "3805": "Treatment type",
    "3806": "Extranodal extension",
    "3807": "Apply",
    "3808": "Unit",
    "3809": "Rule packages",
    "3810": "Prem",
    "3811": "Add ECG findings as number of small squares",
    "3812": "Protocol name",
    "3813": "Comment",
    "3814": "Left eye temporal inferior",
    "3815": "Doctor approval",
    "3816": "after premedication",
    "3817": "Toxicity date",
    "3818": "ischemia",
    "3819": "Weight and lenght",
    "3820": "Dosage",
    "3821": "Not_approved",
    "3822": "Weight (kg)",
    "3823": "Venous beading with leakage",
    "3824": "Global protocols",
    "3825": "Add new protocol note",
    "3826": "Bronchial surgery margin",
    "3827": "Could not be applied",
    "3828": "Protocol name",
    "3829": "Administration",
    "3830": "Account",
    "3831": "Left eye temporal",
    "3832": "Unexpected dose change warning",
    "3833": "Save",
    "3834": "Genetic information",
    "3835": "Distance between distal surgery margin and the tumor (mm)",
    "3836": "Add genetic",
    "3837": "Dosage",
    "3838": "Cover retraction",
    "3839": "Save",
    "3840": "Hard exudate",
    "3841": "Radiotherapy",
    "3842": "Cancel Day",
    "3843": "Eye follow up",
    "3844": "Necrosis rate(%)",
    "3845": "Edit note",
    "3846": "Planned dosage",
    "3847": "Feature",
    "3848": "Length (cm)",
    "3849": "Delay days after protocol starting date",
    "3850": "Body mass index",
    "3851": "Optical disc staining",
    "3852": "WHO grade of the tumor",
    "3853": "Profile",
    "3854": "Name",
    "3855": "After",
    "3856": "Capillary nonperfusion sites",
    "3857": "Date",
    "3858": "18.5 < BMI < 24.9",
    "3859": "What is the level for response to neoadjuvant therapy",
    "3860": "Search in all drugs",
    "3861": "Left eye nasal",
    "3862": "Physician",
    "3863": "Lymph vascular invasion",
    "3864": "Choose protocol",
    "3865": "Applying type",
    "3866": "Operations",
    "3867": "Is tumor originated from a polyp",
    "3868": "Delay day",
    "3869": "ml/min",
    "3870": "Vascular Leakage",
    "3871": "Report",
    "3872": "Please select and add protocol groups to save on",
    "3873": "Hızlı takip",
    "3874": "Dimension of tumor focus",
    "3875": "Use this title",
    "3876": "Before",
    "3877": "Papillom ",
    "3878": "Shared",
    "3879": "General physical examination",
    "3880": "Left eye nasal superior",
    "3881": "Drug could not be given (not recorded in the system)",
    "3882": "Patient name",
    "3883": "Protocol total days",
    "3884": "Creatinine",
    "3885": "Neuroendocrine tumor histopathology and grade",
    "3886": "Add ECHO",
    "3887": "Non-arterial ischemic optic neuropathy",
    "3888": "Evening",
    "3889": "back clear",
    "3890": "DRUG",
    "3891": "Distance between mesenteric surgery margin and the tumor (mm)",
    "3892": "Pharmacy",
    "3893": "Distance between mucosal margin and the tumor (mm)",
    "3894": "Protocol list",
    "3895": "Name",
    "3896": "Actual weight",
    "3897": "Edit",
    "3898": "Health details",
    "3899": "Rule engine started",
    "3900": "Medicines",
    "3901": "Volume to be applied",
    "3902": "Please wait when we are working on patient details",
    "3903": "Drug termination",
    "3904": "Weight",
    "3905": "GFR",
    "3906": "Ksantelesma",
    "3907": "Arterial filling defect (carotid obstruction)",
    "3908": "Maximum character count problem.",
    "3909": "Mural type",
    "3910": "Neovascularization (on disc)",
    "3911": "Start date",
    "3912": "Laboratory",
    "3913": "Operation",
    "3914": "Calculated",
    "3915": "Histologic type",
    "3916": "Choroidal neovascular membrane with leakage",
    "3917": "Disease progression has been detected",
    "3918": "Tumor growth pattern",
    "3919": "Add ECG",
    "3920": "Preretinal hemorrhage",
    "3921": "Physical examination",
    "3922": "Medication",
    "3923": "Edit toxicity",
    "3924": "Imaging",
    "3925": "Freq",
    "3926": "Preparation",
    "3927": "Is there transanal disk excision",
    "3928": "Definition",
    "3929": "Drug is given",
    "3930": "Reference",
    "3931": "Wait after administration (minutes)",
    "3932": "Physician approval",
    "3933": "before meal",
    "3934": "Messages",
    "3935": "Given dosage",
    "3936": "Lymph vascular invasion type",
    "3937": "Administered dosage",
    "3938": "Note",
    "3939": "Not indicated",
    "3940": "ECHO",
    "3941": "Weight & height",
    "3942": "Starting delay",
    "3943": "Glandular differentiation percentage (%)",
    "3944": "Close",
    "3945": "Message",
    "3946": "Given",
    "3947": "Symptom",
    "3948": "Protokolü sil",
    "3949": "Neovascularization (on disc) with leakage",
    "3950": "Vitreous hemorrhage",
    "3951": "Laboratory",
    "3952": "Nutrition",
    "3953": "Decision-Making System",
    "3954": "Entropium",
    "3955": "Right eye temporal inferior",
    "3956": "Save protocol",
    "3957": "No operation yet",
    "3958": "Copy",
    "3959": "Save",
    "3960": "The last status of this patient has not been known",
    "3961": "All of the options",
    "3962": "Pharmacy",
    "3963": "Dosage adjustment reasons",
    "3964": "Left eye center superior",
    "3965": "Shared protocols",
    "3966": "Preparation",
    "3967": "Could not be applied (patient refused)",
    "3968": "Add Note",
    "3969": "and",
    "3970": "Normal weight",
    "3971": "lbs",
    "3972": "Yes & No",
    "3973": "Brands",
    "3974": "Macular edema",
    "3975": "Cancel",
    "3976": "Can mitotic rate be determined",
    "3977": "Why distal surgery margin cannot be assessed",
    "3978": "Match",
    "3979": "Dacyrocystitis",
    "3980": "min",
    "3981": "Relation with HPV",
    "3982": "All operations",
    "3983": "Patient&Drug matching",
    "3984": "In the same day",
    "3985": "Medication brands selection",
    "3986": "Sentinel lymph node assessment method",
    "3987": "Neovascularization (other) with leakage",
    "3988": "Ideal weight",
    "3989": "New treatment",
    "3990": "Subretinal hemorrhage",
    "3991": "Patient protocols",
    "3992": "Apply Consecutive Days",
    "3993": "Administration",
    "3994": "Save",
    "3995": "Protocol type",
    "3996": "BSA",
    "3997": "Base of appendix involvement",
    "3998": "CNVM",
    "3999": "kg",
    "4000": "Calculated",
    "4001": "Used medicines",
    "4002": "Please choose protocol before",
    "4003": "Rule engine",
    "4004": "Chalazion",
    "4005": "Starting date",
    "4006": "Anterior segment, Fundus",
    "4007": "BMI > 24.9",
    "4008": "Over weight",
    "4009": "Preview protocol",
    "4010": "Chemotherapy open",
    "4011": "Relationship of Tumor to Esophagogastric Junction",
    "4012": "Patient details",
    "4013": "Treatment list",
    "4014": "Planning",
    "4015": "Deep tumor margin",
    "4016": "Fibrovascular change with leakage",
    "4017": "Exitus",
    "4018": "Close",
    "4019": "Su.",
    "4020": "Show operations",
    "4021": "User",
    "4022": "Creatinine date",
    "4023": "Lauren classification of adenocarcinoma",
    "4024": "Visibility",
    "4025": "Actinic keratosis",
    "4026": "Autorefractometer",
    "4027": "Show protocol details",
    "4028": "Mixture drug",
    "4029": "New chemotherapy",
    "4030": "Biopsy",
    "4031": "Fluid",
    "4032": "Patient details",
    "4033": "Show Protocol Details",
    "4034": "Add Note",
    "4035": "During",
    "4036": "Ratio for myometrium invasion",
    "4037": "Settings",
    "4038": "Administration type",
    "4039": "Actions",
    "4040": "Morning",
    "4041": "Favorite",
    "4042": "Proximal surgery margin",
    "4043": "Microaneurysm with leakage",
    "4044": "Cycles",
    "4045": "Close",
    "4046": "Body",
    "4047": "Initial date",
    "4048": "Minutes ago",
    "4049": "Sex",
    "4050": "Right eye nasal inferior",
    "4051": "Sec.",
    "4052": "Result",
    "4053": "Close",
    "4054": "Surgery",
    "4055": "Please select",
    "4056": "BSA",
    "4057": "Blot hemorrhage",
    "4058": "Dr.",
    "4059": "Infusion rate",
    "4060": "Delay reason",
    "4061": "Save",
    "4062": "State",
    "4063": "Adjusted weight",
    "4064": "microaneursym",
    "4065": "Mixing medication",
    "4066": "Duration days",
    "4067": "Visibility",
    "4068": "Left eye center inferior",
    "4069": "Patient ID",
    "4070": "Optical atrophy",
    "4071": "Planned",
    "4072": "Physician approval",
    "4073": "Nevus",
    "4074": "Protocol note",
    "4075": "Comment",
    "4076": "cm",
    "4077": "Close",
    "4078": "Protocol group",
    "4079": "Add Drug",
    "4080": "Default header",
    "4081": "Glandular(Acinar) / Tubular Differantiation",
    "4082": "After Meal",
    "4083": "Cancel",
    "4084": "Architectural Patterns",
    "4085": "Rule name",
    "4086": "Laser spots",
    "4087": "Largest diameter",
    "4088": "Passport No",
    "4089": "Nrs.",
    "4090": "Histopathology prediction",
    "4091": "Add Toxicity",
    "4092": "Left eye temporal superior",
    "4093": "Extensive intraductal component",
    "4094": "Show order",
    "4095": "Sebase cyst",
    "4096": "Drugs",
    "4097": "Not Started",
    "4098": "BMI",
    "4099": "Drug termination",
    "4100": "Bazal cell carcinoma",
    "4101": "Please select the drug",
    "4102": "Left eye center",
    "4103": "The type of lymph node metastasis",
    "4104": "Physician",
    "4105": "Visual acuity",
    "4106": "Close",
    "4107": "Chemotherapy termination",
    "4108": "Recommended repeats",
    "4109": "Protokolü düzenle",
    "4110": "Brand selection",
    "4111": "Order",
    "4112": "Dashboard",
    "4113": "BMI < 18.5",
    "4114": "Right eye center",
    "4115": "Epicrisis",
    "4116": "Height",
    "4117": "BSA date",
    "4118": "Creatinine",
    "4119": "Papilla edema with leakage",
    "4120": "Treatment completed",
    "4121": "Dosage adjustment",
    "4122": "Drug could not be given (incorrect dose)",
    "4123": "Premedication",
    "4124": "Prepared (robotic)",
    "4125": "Mitotic rate /10 high-power fields (HPF)",
    "4126": "Administration",
    "4127": "Mesenteric tumor margin",
    "4128": "Law performance",
    "4129": "inch",
    "4130": "Dermatochalasia",
    "4131": "Left eye nasal inferior",
    "4132": "Why proximal surgery margin cannot be assessed",
    "4133": "Tedavide işlem başladı",
    "4134": "Drop / minute",
    "4135": "Patient refuse",
    "4136": "Myometrrium thickness (mm)",
    "4137": "Add Note to Day",
    "4138": "User",
    "4139": "Dot hemorrhage",
    "4140": "Trophoblastic differentiation percentage (%)",
    "4141": "Duration (min)",
    "4142": "Splinter hemorrhage",
    "4143": "Ideal weight",
    "4144": "Cycle",
    "4145": "The type of tumor that is originated from",
    "4146": "Mitotic rate range",
    "4147": "Trifocal IOL",
    "4148": "TC NO",
    "4149": "Blockage due to vitreous hemorrhage",
    "4150": "Drug could not be given",
    "4151": "Tumor invasion",
    "4152": "Diameter (mm)",
    "4153": "No creatinine record yet.",
    "4154": "Tumor Location in terms of peritoneal reflection",
    "4155": "Apply & Order",
    "4156": "Actions",
    "4157": "days before",
    "4158": "before premedication",
    "4159": "Drug days",
    "4160": "Medications",
    "4161": "before bed",
    "4162": "Mixing medications",
    "4163": "Staging",
    "4164": "Cycle",
    "4165": "Cancel treatment termination",
    "4166": "Zone - 1",
    "4167": "Add Protocol",
    "4168": "Pharmacy",
    "4194": "Add",
    "4195": "Zone - 3",
    "4196": "Save changes",
    "4197": "Close",
    "4198": "Cutaneus horn",
    "4199": "Why cannot histologic grade be determined",
    "4200": "Height",
    "4201": "Approve drug",
    "4202": "Administration",
    "4203": "Right eye temporal superior",
    "4204": "Please select the main drug",
    "4205": "Histopathology",
    "4206": "Group",
    "4207": "Skleral fixation IOL",
    "4208": "Change (%)",
    "4209": "Started at",
    "4210": "Zeiss cyst",
    "4211": "Can tumor diameter be asssessed",
    "4212": "Prepared (manually)",
    "4213": "Last operation",
    "4214": "Medication",
    "4215": "Squamous cell carcinoma",
    "4216": "Drop / minute",
    "4217": "Right eye center superior",
    "4218": "GFR calculation and creatinine date",
    "4219": "ECHO details",
    "4220": "Which functional type of tumor",
    "4221": "Duration",
    "4222": "Fluid brands",
    "4223": "Diabetic papillopathy",
    "4224": "Right eye nasal",
    "4225": "New weight and height",
    "4226": "Noon",
    "4227": "Comments",
    "4228": "Under weight",
    "4229": "Treatment list",
    "4230": "LLN",
    "4231": "Medication name",
    "4232": "Patient-drug match",
    "4233": "Chemotherapy protocols",
    "4234": "Patient",
    "4235": "Please consider patient can be colon cancer!",
    "4236": "Cycle & Drug view",
    "4237": "One of the options",
    "4238": "Chemotherapy list",
    "4239": "PCIOL",
    "4240": "Wait after infusion",
    "4241": "During premedication",
    "4242": "Apply & Order",
    "4243": "No weight and length data yet. Please record.",
    "4244": "Dosage",
    "4245": "Drug could not be given (out of stock)",
    "4246": "New package",
    "4247": "Socket",
    "4248": "List of brands",
    "4249": "New rule",
    "4250": "Toric and trifocal IOL",
    "4251": "Why mesenteric surgery margin cannot be assessed",
    "4252": "Pharm.",
    "4253": "Bifocal IOL",
    "4254": "or",
    "4255": "Depth of myometrium invasion (mm)",
    "4256": "Laboratory tests",
    "4257": "Drug name",
    "4258": "Patient cannot tolerate this treatment",
    "4259": "Delay (days)",
    "4260": "Female",
    "4261": "Why circumferential surgery margin cannot be assessed",
    "4262": "Sonlandırıldı",
    "4263": "Did the patient receive neoadjuvant treatment",
    "4264": "days after",
    "4265": "Chemotherapy details",
    "4266": "Weight (lbs)",
    "4267": "WHO classification of adenocarcinoma",
    "4268": "Approved",
    "4320": "Right eye center inferior",
    "4330": "Please select the drug message",
    "4336": "Public",
    "4337": "FIGO grade",
    "4338": "Ectropion",
    "4353": "Add BSA",
    "4356": "Plan new biopsy",
    "4357": "Volume",
    "4359": "Add Crea",
    "4367": "Active",
    "4368": "Count",
    "4371": "Macroscopic intactness of mesorectum",
    "4373": "Applying",
    "4374": "Show all operations",
    "4375": "Is this tumor a neuroendocrine tumor (NET) or carcinoma",
    "4378": "Fast follow-Up",
    "4380": "Vision and eye movements",
    "4381": "BSA date",
    "4382": "Distance between deep surgery margin and the tumor (mm)",
    "4384": "Papilla edema",
    "4395": "Wait after this cycle (days)",
    "4396": "Could not prepared (dose error)",
    "4397": "minutes",
    "4398": "Delete",
    "4399": "Can Ki-67 labeling index be assessed",
    "4400": "My Protocols",
    "4401": "Interventions",
    "4402": "Analyze results",
    "4408": "All chemotherapy protocols",
    "4442": "Muscle, chest invasion",
    "4443": "Warning",
    "4444": "Protocol premedication",
    "4445": "Protocol schema",
    "4460": "Creatinine",
    "4461": "Education",
    "4462": "Mullerian differentiation percentage (%)",
    "4476": "Is there medullary tumor component",
    "4533": "Ki-67 labeling index interval",
    "4550": "chemotherapy",
    "4553": "Melanoma",
    "4557": "Period (days)",
    "4558": "Applied",
    "4559": "Automatic calculated dosage changes are applied",
    "4560": "Note",
    "4563": "Toxicity",
    "4564": "Required characters are 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, .",
    "4572": "Deep tumor margin",
    "4573": "Preparation",
    "4574": "Repeat",
    "4575": "View protocol",
    "4576": "Search patient",
    "4577": "Delay",
    "4578": "ULN",
    "4579": "Surgery Margin",
    "4587": "Brand selection",
    "4588": "Apply all in day",
    "4589": "Age",
    "4593": "Right eye nasal superior",
    "4600": "Oncology follow",
    "4601": "Circumferantial tumor margin",
    "4602": "Show schema",
    "4603": "Functional type of tumor",
    "4604": "Psychiatry",
    "4605": "Day note",
    "4606": "Please choose a visibility type to see the protocols in the selected group",
    "4607": "The last creatinine recording was more than 30 days ago",
    "4608": "required_characters are; ",
    "4610": "This field is required. Please fill it.",
    "4618": "Max decimal number is; ",
    "4627": "required_characters are; ",
    "4641": "Period adjustment has been changed more than expected. The percent of analyze (%); ",
    "4644": "Delay after initial date of drug adjustment has been changed more than expected. The percent of analyze (%); ",
    "4648": "Please select the days of this drug",
    "4649": "Please select the days of the drug which is connected to this note.",
    "4652": " minimum value error ",
    "4658": "This field is required. Please fill it.",
    "4662": "Please select the days of this message",
    "4682": "adjustment has been changed more than expected. The percent of analyze (%); ",
    "4689": "Max decimal number is; ",
    "4690": "character length is more than expected. Expected length is; ",
    "4779": "required_characters are; ",
    "48": "Drug",
    "4802": "Please select the days of the drug which is connected to this message.",
    "4822": "This field is required. Please fill it.",
    "4906": "Max decimal number is; ",
    "5121": "required characters are [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]",
    "5137": "You have written under the minimum value. Minimum value should be; ",
    "5143": "character length is more than expected. Expected length is; ",
    "5144": "Please choose the diagnosis that the patient will receive this therapy because of this diagnosis",
    "5145": "required_characters are; ",
    "5148": "Character length is more than expected. Expected length is; ",
    "5158": "character length is more than expected. Expected length is; ",
    "5169": "Please write your message",
    "5172": "Duration wait after drug administration input field; required_characters are; ",
    "5497": "character length is more than expected. Expected length is; ",
    "5502": "character length is more than expected. Expected length is; ",
    "5507": "You have written under the minimum value. Minimum value should be; ",
    "5525": "dosage adjustment has been changed more than expected. The percent of analyze (%); ",
    "5962": "You have written under the minimum value. Minimum value should be; ",
    "5963": "Please select the days of this note",
    "5974": "required_characters are; ",
    "5982": "adjustment has been changed more than expected. The percent of analyze (%); ",
    "5983": "character length is more than expected. Expected length is; ",
    "5984": "Max decimal number is; ",
    "5985": "required characters are [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]",
    "5986": "This field is required. Please fill it.",
    "5987": " minimum value error ",
    "5988": "You have exceeded the maximum value. Maximum value should be; ",
    "5989": "Please write the protocol name and/or copy autmatically created title",
    "5990": "Cycle number adjustment has been changed more than expected. The percent of analyze (%); ",
    "5991": "Max decimal number is; ",
    "5992": "Duration-wait adjustment has been changed more than expected. The percent of analyze (%); ",
    "5993": "character length is more than expected. Expected length is; ",
    "5995": "required characters are [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]",
    "5998": "Please select the days of this laboratory",
    "5999": "Duration wait after drug administration input field; character length is more than expected. Expected length is; ",
    "6000": "You have exceeded the maximum value. Maximum value should be; ",
    "6001": "You have exceeded the maximum value. Maximum value should be; ",
    "6007": "required characters are [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]",
    "6009": "character length is more than expected. Expected length is; ",
    "6010": "Please select the days of the drug which is connected to this premedication.",
    "6011": "required_characters are; ",
    "6012": "This field is required. Please fill it.",
    "6013": "Please select the days of the drug which is connected to this laboratory.",
    "6014": " minimum value error ",
    "6015": "Please select the administration type",
    "6016": "You have exceeded the maximum value. Maximum value should be; ",
    "6017": "You have written under the minimum value. Minimum value should be; ",
    "6018": "Duration wait after drug administration input field; duration has been changed more than expected. The percent of analyze (%); ",
    "6019": "Please select the frequency",
    "6020": "dosage adjustment has been changed more than expected. The percent of analyze (%); ",
    "6021": "Please choose the type of this treatment",
    "6022": "Please select the days of this premedication",
    "6023": "Please select the therapy unit",
    "6024": "character length is more than expected. Expected length is; ",
    "6025": "Please choose the starting date of treatment",
    "6026": "Please select the protocol group",
    "6027": "character length is more than expected. Expected length is; ",
    "6028": "required_characters are; ",
    "6031": "Please select the tests of this laboratory",
    "6033": "required characters are; ",
    "6034": "character length is more than expected. Expected length is; ",
    "6036": "Please select the unit",
    "6039": "dosage adjustment has been changed more than expected. The percent of analyze (%); ",
    "6041": "Selected date is before more than 30 days",
    "6043": "required characters are; ",
    "6044": "character length is more than expected. Expected length is; ",
    "6051": "The last weight and height recording was more than 30 days ago",
    "6052": "Required",
    "6054": "Date of weight and length is required field. Please select the date.",
    "6064": "Please select the visibility of this protocol",
    "6068": "required_characters are; ",
    "6069": "Please write your note",
    "6077": "Save BMI, weight, and length",
    "6078": "Save laboratory",
    "6080": "Turkish",
    "6081": "Save patient details",
    "6082": "Drug approval; Apply this drug in our center(with requesting brands)",
    "6084": "Treatment analyze",
    "6099": "(>=) equal or bigger then",
    "6112": "(<>) not equal to",
    "6113": "(<=) equal or smaller then",
    "6114": "(=) equal to",
    "6115": "(<) smaller than",
    "6116": "(>) bigger than",
    "6117": "Drug could not be given (not recorded to system)",
    "6118": "Drug could not be given.(out of stock)",
    "6119": "Drug could not be given (incorrect dose?)",
    "6120": "Drug approving canceled.",
    "6121": "Medication has been administered. But all of the medication could not be administered.",
    "6122": "Medication could not be administered. Because vascular tract could not be determined.",
    "6123": "Medication could not be administered.",
    "6124": "The drug is not matching with this patient.",
    "6125": "Patient and drug match has been successfully completed",
    "6126": "All amount of medication has been administered.",
    "6127": "The patient is going to apply this therapy by him/herself",
    "6134": "Radiotherapy applied",
    "6135": "The patient declared, receiving this drug at home.",
    "6136": "Treatment approving has been withdrawn",
    "6137": "This drug is cancelled for this day",
    "6138": "The patient received this treatment before",
    "6139": "Apply this drug in our center(with requesting brands)",
    "6140": "Apply this drug in our center (without pharmacy order)",
    "6141": "Apply this drug in our center (without pharmacy order)(a clinical trial patient)",
    "6142": "This drug was administered in another center.",
    "6143": "Brand selection canceled.",
    "6144": "The brands have been selected.",
    "6145": "Brand selection have been edited.",
    "6146": "No operation yet.",
    "6147": "Medication could not be administered. Because the patient refused therapy",
    "6148": "Drug could not be prepared (Dose error?)",
    "6149": "Drug could not be prepared",
    "6150": "Prepared (Fully automatic system)",
    "6151": "Prepared (Manually)",
    "6152": "Click to preview and reporting the protocol",
    "6153": "Click to select the next day therapy of the patient",
    "6154": "Click to select one of the drugs of the patient",
    "6155": "Patient has applied",
    "6156": "Click to reset all changes",
    "6157": "You can write your note here.",
    "6170": "Do you want to complete your saving operation?",
    "6171": "Patient has applied differently",
    "6172": "day before",
    "6198": "Before premedication",
    "6200": "Terminated",
    "6201": "Click to select from first day to the next day therapy of the patient",
    "6202": "Patient has applied as recommended",
    "6203": "Please be careful about warnings",
    "6204": "day later",
    "6205": "Patient didnot apply",
    "6206": "Patient applied as not recommended",
    "6207": "day later",
    "6208": "day ago",
    "6209": "After premedication",
    "6210": "note",
    "6211": "Writing language = ",
    "6212": "Click to select the last day therapy of the patient",
    "6213": "New drug recommended",
    "6214": "msg",
    "6215": "days ago",
    "6216": "Click to save all changes",
    "6217": "Please first confirm all the warnings. Do you confirm?",
    "6218": "Today",
    "6219": "Treatment finished",
    "6220": "Click to close protocol edit mode",
    "6221": "Treatment started",
    "6222": "Click to close premedications",
    "6223": "Click to show premedications",
    "6224": "lab",
    "6225": "Please first select the protocol group",
    "6226": "Please first correct the validations.",
    "6227": "days later",
    "6228": "Previously prescribed",
    "6229": "Click to unselect the all therapy days of the patient",
    "6230": "There is no required or warning fields in this operation. You can continue...",
    "6231": "Please first complete the required fields.",
    "6232": "Abnormality",
    "6233": "New drug prescribed",
    "6234": "Click to open protocol edit mode",
    "6235": "Click to select the all days of therapies of the patient",
    "6236": "day during",
    "6237": "Patient has already receiving",
    "6238": "English",
    "6239": "Turkish",
    "6240": "Everybody",
    "6241": "any record",
    "6242": "requesting data",
    "6243": "old recorded data",
    "6244": "Turkish",
    "6245": "in last ... days",
    "6246": "last value",
    "6247": "Rest report",
    "6248": "Drug report",
    "6249": "Status report",
    "6250": "Material report",
    "6251": "treatment list",
    "6252": "new treatment",
    "6253": "patient follow-up",
    "6254": "chemotherapy list",
    "6255": "uploading documents",
    "6256": "ID information",
    "6257": "education",
    "6258": "home page",
    "6259": "i̇stem",
    "6260": "lab result",
    "6261": "patient medications",
    "6262": "pharmacy",
    "6275": "click to find your registered patient",
    "6276": "transactions",
    "6286": "version",
    "6291": "find sick",
    "6299": "number of data records",
    "6302": "user settings",
    "6305": "pole star",
    "6307": "Decision making system",
    "6310": "help",
    "6324": "hospital settings",
    "6353": "click for help",
    "6379": "detailed protocol list",
    "6381": "edit",
    "6384": "whs modules",
    "6386": "new patient",
    "6395": "click to add new patient",
    "6581": "record view",
    "6589": "fullscreen",
    "6597": "friend relationships with other parameters",
    "6637": "duplicate data model",
    "6653": "preview it",
    "6665": "modules",
    "6690": "projects",
    "6698": "model editing",
    "6717": "about",
    "6749": "new information",
    "6765": "sil",
    "6772": "template title",
    "6775": "registration pages to show",
    "6803": "2. parameter selection",
    "7020": "add record",
    "7025": "data models",
    "7042": "Pathology",
    "7044": "Genetic",
    "7045": "Chemotherapy",
    "7048": "ECG",
    "7071": "article",
    "7072": "undo changes",
    "7119": "group title",
    "7124": "Contact",
    "7131": "authorization",
    "7145": "creating a new version",
    "7159": "the second parameter is not yet selected",
    "7168": "his",
    "7218": "delete relationship",
    "7239": "copy",
    "7246": "patient characteristics relationships",
    "7249": "Yes",
    "76": "No",
    "905": "down",
    "9588": "Male",
    "9760": "Increase",
    "9761": "Decrease",
    "9904": "No",
    "9939": "new"
  }
};

