import * as MutationTypes from '../mutation-types';
import Vue from 'vue';

const state = {
  device: {
    'isMobile': false,
    'isTablet': false
  },
  websocket: {
    'status': false,
  },
  sidebar: {
    'opened': false,
    'hidden': false,
    'control_on_user': false
  },
  patient: {
    'selected': false
  },
  modal: {
    'show': false,
    'data': {}
  },
  loading: {
    'status': false,
    'data': { 'label': '' }
  },
  user: {
    'status': 0,
    'data': {}
  },
  lang: '',
  screen_size: {
    'width': 0,
    'height': 0
  }
};

const mutations = {
  [MutationTypes.MutationToggleDevice](state, device) {
    state.device.isMobile = device === 'mobile';
    state.device.isTablet = device === 'tablet';
  },

  [MutationTypes.MutationToggleSidebar](state, opened) {
    if (state.device.isMobile) {
      state.sidebar.opened = opened;
    } else {
      state.sidebar.opened = true;
    }
  },

  [MutationTypes.MutationLoading](state, loading_data) {
    state.loading = loading_data;
  },

  [MutationTypes.MutationSidebarControl](state, close_or_open) {
    state.sidebar.hidden = close_or_open;
  },

  [MutationTypes.MutationPatientData](state, data) {
    if (data) {
      state.patient = data;
      state.patient.selected = true;
    }
  },

  [MutationTypes.MutationWebSocket](state, websocket_status) {
    state.websocket.status = websocket_status;
  },

  [MutationTypes.MutationLang](state, lang) {
    state.lang = lang;
  },

  [MutationTypes.MutationScreenSize](state, screen_size) {
    state.screen_size = screen_size;
  },

  [MutationTypes.MutationModal](state, data) {
    state.modal.data = data;
  },

  [MutationTypes.MutationModalShow](state, status) {
    state.modal.show = status;
  },

  [MutationTypes.MutationUser](state, data) {
    state.user.status = data.status;
    state.user.data = data.data;
  },

};

export default {
  state,
  mutations
};

