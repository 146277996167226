import { Layers } from './Layers';
import { WDMR_LINKS } from '@/config/index';
import WmanagerService from './wmanager';

class WmanagerApi {

  static get_user_access_token = function(creds) {
    return new Promise((resolve, reject) => {
      // console.log('get_user_access_token', creds)
      let data = {
        'grant_type': 'password',
        'username': creds.username,
        'password': creds.password
      };
      WmanagerService.get_user_access_token(data)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

  static get_user_mail_list = function(id_list) {
    return new Promise((resolve, reject) => {
      let data = {
        'id_list': id_list
      };
      WmanagerService.get_user_mail_list(data)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

  static prepare_wdmr = function(layer_type, edit_wdmr=false, w_id_list=[]) {
    return new Promise((resolve, reject) => {
      // console.log(Layers);
      let query = 'layer_id=' + Layers[layer_type].layer_id;
      let wdmr_data = '';
      if (edit_wdmr && edit_wdmr.data) {
        wdmr_data = edit_wdmr;
      } else {
        wdmr_data = {
          'data': {}
        };
      }
      let data = {
        'wdmr': wdmr_data,
        'w_id_list': w_id_list
      };
      // console.log('data: ', data);
      WmanagerService.save_this_wdmr_to_layer_by_wdmr_id_list(query, data)
        .then(resp => {
          // console.log('save_this_wdmr_to_layer_by_wdmr_id_list: ', resp.data);
          if (resp.data.status_code === '3000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

  static get_wdmr_list_by_wdmr_id = function(layer_type, w_id_list, wdmr_data='1', limit='', offset='', start_type='') {
    return new Promise((resolve, reject) => {
      let query = 'layer_id=' + Layers[layer_type].layer_id + '&wdmr_data=' + wdmr_data.toString();
      if (limit) {
        query += '&limit=' + limit;
      }
      if (offset) {
        query += '&offset=' + offset;
      }
      if (start_type) {
        query += '&start_type=' + start_type;
      }
      let data = {
        'w_id_list': w_id_list
      };
      WmanagerService.get_wdmr_list_by_wdmr_id(query, data)
        .then(resp => {
          // console.log('save_this_wdmr_to_layer_by_wdmr_id_list: ', resp.data);
          // console.log(resp.data);
          if (resp.data.status_code === '3000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

  static get_wdmr_list_by_algorithm = function(sql_text, last_case_point = '', limit='', offset='', order='') {
    return new Promise((resolve, reject) => {
      if (limit) {
        sql_text += ' limit = ' + limit;
      }
      if (offset) {
        sql_text += ' offset = ' + offset;
      }
      if (order) {
        sql_text += ' order = ' + order;
      }
      // console.log('sql_text : ', sql_text);
      let data = {'sql': sql_text, "last_case_point": last_case_point};
      WmanagerService.get_wdmr_list_by_algorithm(data)
        .then(resp => {
          // console.log('save_this_wdmr_to_layer_by_wdmr_id_list: ', resp.data);
          // console.log(resp.data);
          if (resp.data.status_code === '3000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

  static relation_docs_add = function(data) {
    return new Promise((resolve, reject) => {
      WmanagerService.relation_docs_add(data)
        .then(resp => {
          if (resp.data.status_code === '3000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

}

export { WmanagerApi };

