var Layers = {
  'organization': {
    'w_id_list': [],
    'layer_id': '20',
    'wdm_type': 'wdm307'
  },
  'operation': {
    'w_id_list': [],
    'layer_id': '21',
    'wdm_type': 'wdm17'
  },
  'organization_users': {
    'w_id_list': [],
    'layer_id': '22',
    'wdm_type': 'wdm22'
  },
  'user_organizations': {
    'w_id_list': [],
    'layer_id': '25',
    'wdm_type': 'wdm307'
  }
};

export { Layers };

