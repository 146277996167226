import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_phrases (data) {
    return Vue.http.post(API_BASE_URL + 'language/get_phrases', data);
  },
  control_wdmr16_list (data) {
    return Vue.http.post(API_BASE_URL + 'language/control_wdmr16_list', data);
  },
  auto_translate (data) {
    return Vue.http.post(API_BASE_URL + 'language/auto_translate', data);
  },
  give_permission_to_this_user (data) {
    return Vue.http.post(API_BASE_URL + 'language/give_permission_to_this_user', data);
  },
  save_phrase (data) {
    return Vue.http.post(API_BASE_URL + 'language/save_phrase', data);
  },
  save_new_phrases (data) {
    return Vue.http.post(API_BASE_URL + 'language/save_new_phrases', data);
  },
  create_user_tasks (data) {
    return Vue.http.post(API_BASE_URL + 'language/create_user_tasks', data);
  },
  translate_from_to_other_languages (data) {
    return Vue.http.post(API_BASE_URL + 'language/translate_from_to_other_languages', data);
  },
  get_wdm16_id_meaning_with_lang_id (data) {
    return Vue.http.post(API_BASE_URL + 'language/get_wdm16_id_meaning_with_lang_id', data);
  },
  get_phrases_count () {
    return Vue.http.get(API_BASE_URL + 'language/get_phrases_count');
  },
  get_users_have_language_permissions () {
    return Vue.http.get(API_BASE_URL + 'language/get_users_have_language_permissions');
  }
};

